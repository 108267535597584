<template>
  <v-form ref="form" v-model="valid" class="tayoForm" @submit.prevent="subscribeNewsletter">
    <v-row align="center" no-gutters>
      <v-col
        cols="10"
      >
        <v-text-field
          v-model="emailAddress"
          class="newsletter-input mr-2"
          label="your@email.com"
          variant="underlined"
          :rules=" [
            v => !!v || $t('error.email_required'),
            v => (/.+@.+/.test(v)) || $t('error.email_valid')
          ]"
          aria-label="Newsletter email"
          name="email"
          title="Newsletter email"
          aria-placeholder="your@email.com"
          required
          @keyup.enter="subscribeNewsletter"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          size="large"
          color="rgba(255, 255, 255, 0.15)"
          :icon="mdiSend"
          aria-label="Subscribe Tayo Mag"
          flat
          :disabled="!valid"
          @click="subscribeNewsletter"
        />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <p v-if="subscribeResult !== ''" v-html="subscribeResult" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script scoped>
import { mdiSend } from '@mdi/js'

export default {
  data: () => ({
    // @TODO : Set in a config file
    mdiSend,
    region: 'na1',
    valid: false,
    emailAddress: '',
    subscribeResult: ''
  }),
  watch: {
    emailAddress: 'validateField'
  },
  methods: {
    validateField () {
      this.$refs.form.validate()
    },
    subscribeNewsletter () {
      const data = {
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: this.emailAddress
          }
        ]
        // legalConsentOptions: { // Include this object when GDPR options are enabled
        //   consent: {
        //     consentToProcess: true,
        //     text: 'I agree to allow Example Company to store and process my personal data.',
        //     communications: [
        //       {
        //         value: true,
        //         subscriptionTypeId: 999,
        //         text: 'I agree to receive marketing communications from Example Company.'
        //       }
        //     ]
        //   }
        // }
      }
      if (this.valid) {
        $fetch('/api/subscribe/' + this.$i18n.locale, { method: 'post', body: data })
          .then((response) => {
            this.subscribeResult = response ? response.inlineMessage : this.$t('sentence.newsletterSubscriptionOk')
          })
          .catch(() => {
            this.subscribeResult = this.$t('sentence.newsletterSubscriptionError')
          })
      } else {
        this.subscribeResult = this.$t('error.invalidForm')
      }
    }
  }
}
</script>
<style lang="scss">
  .tayoForm{
    .newsletter-input{
      .v-field__field{
        height: 80px;
      }
      .v-label{
        font-family: 'MaisonNeue', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: rgba(255, 255, 255, 0.2);
        @media (max-width: 959px){
          font-size: 7vw;
        }
      }
      .v-label--active, .v-label--focus{
        font-size: unset;
        font-style: unset;
        font-weight: unset;
        line-height: unset;
      }
      .v-field--active .v-label {
        font-size: unset;
        font-style: unset;
        font-weight: unset;
        line-height: unset;
      }

    }
  }

</style>
